"use client";

import { UserProfile, Workspace, useAuth } from ".";
import React, { createContext, useEffect, useState } from "react";


import { WorkspaceRoles, formatStorage } from "@palette.tools/model";


export function getWorkspaceRole(workspace: Workspace, profile: UserProfile) {
  if (workspace.is_role("admin", profile.id)) return WorkspaceRoles.admin;
  return WorkspaceRoles.member;
}

export interface FileValidation {
  isValid: boolean,
  reason?: string,
}

export function validateWorkspaceFile(workspace: Workspace | null, f: File | null): FileValidation {
  if (!f) {
    return { isValid: false, reason: "No file selected." };
  }
  if (!workspace?.data.storage_max_file_size || !workspace?.data.storage_limit || typeof(workspace?.data.storage_size) !== "number") {
    return { isValid: false, reason: "Workspace is not set up for file uploads." };
  }
  else if (f.size > workspace.data.storage_max_file_size) {
    return { isValid: false, reason: `File is too large. Max file size is ${formatStorage(workspace.data.storage_max_file_size)}.` };
  }
  else if (f.size > workspace.data.storage_limit - workspace.data.storage_size) {
    let bytesLeft = workspace.data.storage_limit - workspace.data.storage_size;
    bytesLeft = bytesLeft > 0 ? bytesLeft : 0;
    return { isValid: false, reason: `${formatStorage(bytesLeft)} left in workspace. File is ${formatStorage(f.size)}` };
  }
  return { isValid: true }
}


export const WorkspaceAuthContext = createContext<ReturnType<typeof useAuth>>({
  profile: undefined,
  isLoading: true,
  isPreloaded: false,
  error: undefined,
  signOut: async () => {},
});


interface AuthProviderProps {
  children: React.ReactNode;
}


export const useWorkspaceAuth = () => {
  return React.useContext(WorkspaceAuthContext);
}


export const WorkspaceAuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

  const workspace = Workspace.useInContext();
  const authResult = useAuth();

  useEffect(() => {
    if (!!authResult.profile && !!workspace?.id) {
      // TODO: Placeholder for workspace-related initialization
    }

  }, [authResult.profile, workspace?.id]);


  const wrappedComponents = <WorkspaceAuthContext.Provider value={{
    ...authResult,
  }}>{children}</WorkspaceAuthContext.Provider>

  return wrappedComponents;

};
