import { TransactionChunk } from "@instantdb/core";
import { Namespaces } from "./schema";


interface BaseModelEvent {
  key: keyof Namespaces;
  id: string;
  tx: string;
  data: object;
  timestamp: number;
  author: string;
}

export interface CreateEvent extends BaseModelEvent {
  type: "create",
}

export function isCreateEvent(event: ModelEvent): event is CreateEvent {
  return event.type === "create";
}

export interface UpdateEvent extends BaseModelEvent {
  type: "update",
}

export function isUpdateEvent(event: ModelEvent): event is UpdateEvent {
  return event.type === "update";
}

export interface LinkEvent extends BaseModelEvent {
  type: "link",
  data: {
    from: {
      key: keyof Namespaces,
      id: string,
    },
    to: {
      key: keyof Namespaces,
      id: string,
    },
  },
}

export function isLinkEvent(event: ModelEvent): event is LinkEvent {
  return event.type === "link";
}

export interface UnlinkEvent extends BaseModelEvent {
  type: "unlink",
  data: {
    from: {
      key: keyof Namespaces,
      id: string,
    },
    to: {
      key: keyof Namespaces,
      id: string,
    },
  },
}

export function isUnlinkEvent(event: ModelEvent): event is UnlinkEvent {
  return event.type === "unlink";
}

export interface AssignEvent extends BaseModelEvent {
  type: "assign",
}

export function isAssignEvent(event: ModelEvent): event is AssignEvent {
  return event.type === "unlink";
}

export interface UnassignEvent extends BaseModelEvent {
  type: "unassign",
}

export function isUnassignEvent(event: ModelEvent): event is UnassignEvent {
  return event.type === "unassign";
}

export interface TrashEvent extends BaseModelEvent {
  type: "trash",
}

export function isTrashEvent(event: ModelEvent): event is TrashEvent {
  return event.type === "trash";
}

export interface InsertEvent extends BaseModelEvent {
  type: "insert";
  data: {
    parent: {
      key: keyof Namespaces,
      id: string,
    }
    item: {
      key: keyof Namespaces,
      id: string,
    },
    listItem: {
      key: keyof Namespaces,
      id: string,
    },
  },
}

export function isInsertEvent(event: ModelEvent): event is InsertEvent {
  return event.type === "insert";
}

export interface ReorderEvent extends BaseModelEvent {
  type: "reorder";
  data: {
  },
}

export function isReorderEvent(event: ModelEvent): event is ReorderEvent {
  return event.type === "reorder";
}

export type ModelEvent = (
  CreateEvent
  | UpdateEvent
  | LinkEvent
  | UnlinkEvent
  | AssignEvent
  | UnassignEvent
  | TrashEvent
  | InsertEvent
  | ReorderEvent
);

export interface TransactionContext {
  timestamp: number,
  author: string,
}

export type EventWrappedTransactionChunk = {
  event?: ModelEvent;
  chunk?: TransactionChunk<any, any>;
} | ((context: TransactionContext) => {
  event?: ModelEvent;
  chunk?: TransactionChunk<any, any>;
});

export const isEmitVerboseEvents = true;
