"use client";

import { TransactionChunk } from "@instantdb/core";
import {
    InstantObject,
    id,
    init,
    tx,
} from "@instantdb/react";
import { EventWrappedTransactionChunk, Schema, TransactionContext } from "@palette.tools/model.core";

const APP_ID = process.env.INSTANT_APP_ID || process.env.NEXT_PUBLIC_INSTANT_APP_ID || "never";

export const {
  useQuery: useInstantDBQuery,
  transact: instantDBTransact,
  useAuth: useInstantDBAuth,
  _core: instantDBCore,
  auth,
} = init<Schema>({ appId: APP_ID });


export {
    id,
    tx
};
export type {
    InstantObject
};


export async function getInstantUser() {
  return await instantDBCore._reactor.getCurrentUser();
}


export const useQuery = (_query: Parameters<typeof useInstantDBQuery>[0]) => {
  return {
    ...useInstantDBQuery(_query),
    query: _query,
  };
}


async function post_events(events: any[]) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return false;

  const response = await fetch("/api/events", {
    headers: {
      token: user.refresh_token,
    },
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify(events),
  })

  if (!response.ok) {
    console.warn("Failed to post events", response.status, response.statusText);
  }

}


const isTransactDebug = true;
const isTransactDryRun = false;


export async function transact(...chunks: (
  EventWrappedTransactionChunk
  | EventWrappedTransactionChunk[]
  | (EventWrappedTransactionChunk | EventWrappedTransactionChunk[])[])[]
) {

  let before = performance.now();
  const context: TransactionContext = {
    author: (await getInstantUser()).user?.id || "unknown-client",
    timestamp: new Date().getTime(),
  }
  let after = performance.now();
  console.log(`transact context took ${after - before}ms`);

  const flattenedChunks = chunks.flat().flat().map(chunk => {
    if (typeof chunk === "function") return chunk(context);
    return chunk;
  });
  const events = flattenedChunks.map(x => x.event).filter(x => !!x);

  if (isTransactDebug) {
    console.log({ mutations: events })
    //console.log({ mutations: events.filter(x => !!x).map(x => x!.tx).join("\n") });
    if (isTransactDryRun) return;
  }

  const transactionChunks = (flattenedChunks.map(x => x.chunk).filter(x => !!x) as TransactionChunk<any, any>[]);

  // // Function to yield control to the main thread
  // const yieldToMain = () => new Promise(resolve => setTimeout(resolve, 0));

  // // Wrap instantDBTransact to make it interruptible
  // const interruptibleTransact = async (chunks: TransactionChunk<any, any>[]) => {
  //   const yieldInterval = 50; // Yield every 50ms
  //   let lastYieldTime = Date.now();

  //   // Create a custom Promise that we can resolve externally
  //   let resolveTransaction: (value: any) => void;
  //   const transactionPromise = new Promise<any>(resolve => {
  //     resolveTransaction = resolve;
  //   });

  //   // Start the transaction
  //   instantDBTransact(chunks).then(result => resolveTransaction(result));

  //   // Periodically yield control while waiting for the transaction to complete
  //   while (true) {
  //     const elapsedTime = Date.now() - lastYieldTime;
  //     if (elapsedTime >= yieldInterval) {
  //       await yieldToMain();
  //       lastYieldTime = Date.now();
  //     }

  //     // Check if the transaction has completed
  //     const result = await Promise.race([
  //       transactionPromise,
  //       new Promise(resolve => setTimeout(resolve, yieldInterval - elapsedTime))
  //     ]);

  //     if (result !== undefined) {
  //       return result;
  //     }
  //   }
  // };

  // // Execute the transaction
  // before = performance.now();
  // const result = await interruptibleTransact(transactionChunks);
  // await post_events(events);
  // after = performance.now();
  // console.log(`transact took ${after - before}ms`);

  setTimeout(async () => {
    before = performance.now();
    instantDBTransact(transactionChunks).then(async () => {
      post_events(events);
    });
    after = performance.now();
    console.log(`transact took ${after - before}ms`);
  }, 0);

}

export async function transactDryRun(...chunks: (
  EventWrappedTransactionChunk
  | EventWrappedTransactionChunk[]
  | (EventWrappedTransactionChunk | EventWrappedTransactionChunk[])[])[]
) {
  const context: TransactionContext = {
    author: (await getInstantUser()).user?.id || "unknown-client",
    timestamp: new Date().getTime(),
  }

  const flattenedChunks = chunks.flat().flat().map(chunk => {
    if (typeof chunk === "function") return chunk(context);
    return chunk;
  });

  const events = flattenedChunks.map(x => x.event).filter(x => !!x);

  console.log({ mutations: events });

}
